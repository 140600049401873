import {Component, HostListener, Inject, OnInit, Renderer2} from '@angular/core';
import {FooterComponent} from "../footer/footer.component";
import {HeaderComponent} from "../header/header.component";
import {Router, RouterOutlet} from "@angular/router";
import {SideNavComponent} from "../side-nav/side-nav.component";
import {CommonModule, DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    HeaderComponent,
    RouterOutlet,
    SideNavComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  isScrolled: boolean = false;
  public layoutMenuClass = 'layout-menu-expanded';
  public isLoggedIn: boolean = false;

  constructor(private router: Router, private renderer2: Renderer2, @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    const accessToken = window.localStorage.getItem('access');
    if (accessToken) {
      this.router.navigate(['/dashboard'])
    } else {
      this.router.navigate(['/login'])
    }
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    // this.isScrolled = window.pageYOffset > 0;
    this.isScrolled = window.scrollY > 0;
  }

  handleMenuToggle() {
    const htmlElement = this.document.documentElement;

    if (htmlElement.classList.contains(this.layoutMenuClass)) {
      this.renderer2.removeClass(htmlElement, this.layoutMenuClass);
    } else {
      this.renderer2.addClass(htmlElement, this.layoutMenuClass);
    }
  }
}
