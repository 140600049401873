import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private baseUrl: string = 'https://authorization.mynextsalon.com';

  constructor(private http: HttpClient) {

  }

  getMenu(token:string){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(this.baseUrl + '/api/menu', { headers });
  }

}
