import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {CommonModule} from "@angular/common";
import {StoreService} from "../../../services/store.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public loading: boolean = false;
  public passwordVisibility = {
    password: false,
  };

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router,private storeService:StoreService) {
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      rememberme: new FormControl(false),
    })
  }

  login() {
    this.loading = true;
    const payload = this.loginForm.getRawValue();
    this.authService.loginUser(payload).subscribe({
      next: (response) => {
        console.log('data', response)
        localStorage.setItem('access', response?.data?.access);
        localStorage.setItem('refresh', response?.data?.refresh);
        this.loginForm.enable();
        this.loading = false;
        this.router.navigate(['/select-store']);
      },
      error: (error) => {
        console.log('error', error);
        this.loginForm.enable();
        this.loading = false;
      }
    })
  }

  togglePasswordVisibility(field: 'password'): void {
    this.passwordVisibility[field] = !this.passwordVisibility[field];
  }

  getAllErrors(form: FormGroup): { [key: string]: string[] } {
    const errors: { [key: string]: string[] } = {};

    Object.keys(form.controls).forEach(controlName => {
      const control = form.get(controlName);
      if (control && control.invalid) {
        errors[controlName] = this.getControlErrors(control);
      }
    });

    return errors;
  }

  private getControlErrors(control: AbstractControl): string[] {
    const errorMessages: string[] = [];
    const errors = control.errors;
    console.log('Error in Form:', errors);
    errorMessages.push(JSON.stringify(errors))
    return errorMessages;
  }
}
