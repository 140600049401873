import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ISelectStorePayload, IStore, StoreService} from "../../services/store.service";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-store-switcher',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxSpinnerModule],
  templateUrl: './store-switcher.component.html',
  styleUrl: './store-switcher.component.scss'
})
export class StoreSwitcherComponent implements OnInit {
  public storeList: IStore[] = [];
  public loading: boolean = true;
  public accessToken: string = '';
  public storeForm: FormGroup;

  constructor(private storeService: StoreService, private fb: FormBuilder, private router: Router, private spinner: NgxSpinnerService) {
    this.storeForm = this.fb.group({
      store: new FormControl('', [Validators.required])
    })
  }

  ngOnInit() {
    this.accessToken = String(window.localStorage.getItem('access'));
    this.fetchStoreList();
  }

  fetchStoreList() {
    this.loading = true;
    this.spinner.show();
    this.storeService.getStoreList(this.accessToken).subscribe({
      next: (response: any) => {
        if (response?.data?.length === 1) {
          const storeControl = this.storeForm.get('store');
          storeControl?.patchValue(response?.data?.[0]?.key);
          storeControl?.updateValueAndValidity();
          // this.selectStore();
        }
        this.storeList = response?.data;
        this.loading = false;
        this.spinner.hide();
      }, error: (error) => {
        this.loading = false;
        this.spinner.hide();
        console.log('Error:', error);
      }
    })
  }

  selectStore() {
    const payload: ISelectStorePayload = {
      key: this.storeForm.get('store')?.value
    }
    this.spinner.show();
    this.storeService.selectStore(this.accessToken, payload).subscribe({
      next: (response: any) => {
        console.log('data', response);
        localStorage.setItem('access', response?.data?.access_token)
        localStorage.setItem('refresh', response?.data?.refresh_token)
        this.spinner.hide();
        this.router.navigate(['/dashboard'])
      }, error: (error) => {
        console.log('error', error)
        this.spinner.hide();
      }
    })
  }

}

