<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="content-wrapper" *ngIf="!loading">
  <!-- Content: Start -->

  <div class="flex-grow-1 container-p-y container-fluid">


    <div class="app-academy">
      <div class="card p-0 mb-6">
        <div class="card-body d-flex flex-column flex-md-row justify-content-between p-0 pt-6">
          <div class="app-academy-md-25 card-body py-0 pt-6 ps-12">
            <img src="assets/img/illustrations/bulb-light.png"
                 class="img-fluid app-academy-img-height scaleX-n1-rtl" alt="Bulb in hand"
                 data-app-light-img="illustrations/bulb-light.png" data-app-dark-img="illustrations/bulb-dark.png"
                 height="90">
          </div>
          <div class="app-academy-md-50 card-body d-flex align-items-md-center flex-column text-md-center mb-6 py-6">
        <span class="card-title mb-4 lh-lg px-md-12 h4 text-heading">
        Manage Your  Business <br>
          <span class="text-primary text-nowrap">All Stores in one place</span>.
        </span>
            <p class="mb-4 px-0 px-md-2">
              Select your stores to view and manage it
            </p>

            <form class="d-flex align-items-center justify-content-between app-academy-md-80" (ngSubmit)="selectStore()"
            [formGroup]="storeForm">
              <select class="form-select form-control-sm me-4" formControlName="store">
                <option [value]="">Select your store</option>
                <option *ngFor="let store of storeList;" [value]="store.key">{{store.store}}</option>
              </select>

              <button type="submit" class="btn btn-primary btn-icon waves-effect waves-light app-academy-md-20">
                <i  class="ri-search-line ri-22px"></i>
                Submit
              </button>
            </form>
          </div>
          <div class="app-academy-md-25 d-flex align-items-end justify-content-end">
            <img src="assets/img/illustrations/pencil-rocket.png"
                 alt="pencil rocket" height="188" class="scaleX-n1-rtl">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-backdrop fade"></div>
</div>
