<div class="authentication-wrapper authentication-cover">
  <!-- Logo -->
  <a href="index.html" class="auth-cover-brand d-flex align-items-center gap-3">
    <span class="app-brand-logo">
<span style="color:var(--bs-primary);">
   <img src="assets/img/logo/true-invoice-high-resolution-logo.png" alt="" width="30" height="30"
        class="rounded-2">
</span>
</span>
    <span class="app-brand-text text-heading fw-semibold">Materio</span>
  </a>
  <!-- /Logo -->
  <div class="authentication-inner row m-0">

    <!-- /Left Text -->
    <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
      <div>
        <img src="assets/img/illustrations/auth-cover-register-illustration-light.png"
             class="authentication-image-model d-none d-lg-block" alt="auth-model"
             data-app-light-img="illustrations/auth-cover-register-illustration-light.png"
             data-app-dark-img="illustrations/auth-cover-register-illustration-dark.png">
      </div>
      <img src="assets/img/illustrations/tree-2.png" alt="tree" class="authentication-image-tree z-n1">
      <img src="assets/img/illustrations/auth-cover-mask-light.png"
           class="scaleX-n1-rtl authentication-image d-none d-lg-block w-75" height="362" alt="triangle-bg"
           data-app-light-img="illustrations/auth-cover-mask-light.png"
           data-app-dark-img="illustrations/auth-cover-mask-dark.png">
    </div>
    <!-- /Left Text -->

    <!-- Register -->
    <div
      class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-5 px-12 py-4">
      <div class="w-px-400 mx-auto pt-5 pt-lg-0">
        <h4 class="mb-1">Adventure starts here 🚀</h4>
        <p class="mb-5">Make your bussiness easy and fun!</p>

        <form id="formAuthentication" [formGroup]="registerForm" class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework"
              novalidate="novalidate"
              (ngSubmit)="registerUser()">
          <div
            class="form-floating form-floating-outline mb-3 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <input type="text" class="form-control" id="bussinessName" formControlName="store_name"
                   placeholder="Enter your Bussiness Name"
                   [ngClass]="{'is-invalid': registerForm.get('store_name')?.invalid && (!registerForm.get('store_name')?.pristine || registerForm.get('store_name')?.touched) }">
            <label for="username">Bussiness Name*</label>
            <ng-container
              *ngIf="registerForm.get('store_name')?.invalid && (!registerForm.get('store_name')?.pristine || registerForm.get('store_name')?.touched)">
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                <ng-container *ngIf="registerForm.get('store_name')?.hasError('required')">
                  Please enter Store Name
                </ng-container>
                <ng-container *ngIf="registerForm.get('store_name')?.hasError('minlength')">
                  Store Name must be more than 4 characters
                </ng-container>
              </div>
            </ng-container>

          </div>

          <div
            class="form-floating form-floating-outline mb-3 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <input type="text" class="form-control" id="username" formControlName="name"
                   placeholder="Enter your username" autofocus=""
                   [ngClass]="{'is-invalid': registerForm.get('name')?.invalid && (!registerForm.get('name')?.pristine || registerForm.get('name')?.touched) }"
            >
            <label for="username">Name</label>
            <ng-container
              *ngIf="registerForm.get('name')?.invalid && (!registerForm.get('name')?.pristine || registerForm.get('name')?.touched)">
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                <ng-container *ngIf="registerForm.get('name')?.hasError('required')">
                  Please enter Name
                </ng-container>
                <ng-container *ngIf="registerForm.get('name')?.hasError('minlength')">
                 Name must be more than 4 characters
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div
            class="form-floating form-floating-outline mb-3 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <input type="number" class="form-control" id="phone" formControlName="phone"
                   placeholder="Enter your mobile number"
                   [ngClass]="{'is-invalid': registerForm.get('phone')?.invalid && (!registerForm.get('phone')?.pristine || registerForm.get('phone')?.touched) }"
            >
            <label for="username">Mobile</label>
            <ng-container
              *ngIf="registerForm.get('phone')?.invalid && (!registerForm.get('phone')?.pristine || registerForm.get('phone')?.touched)">
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                <ng-container *ngIf="registerForm.get('phone')?.hasError('required')">
                  Please enter Mobile Number
                </ng-container>
                <ng-container *ngIf="registerForm.get('phone')?.hasError('pattern')">
                  Mobile Number must be of 10 digits and numbers only.
                </ng-container>
              </div>
            </ng-container>
          </div>

          <div class="form-floating form-floating-outline mb-3 fv-plugins-icon-container">
            <input type="text" class="form-control" id="email" formControlName="email" placeholder="Enter your email"
                   [ngClass]="{'is-invalid': registerForm.get('email')?.invalid && (!registerForm.get('email')?.pristine || registerForm.get('email')?.touched) }"
            >
            <label for="email">Email</label>
            <ng-container
              *ngIf="registerForm.get('email')?.invalid && (!registerForm.get('email')?.pristine || registerForm.get('email')?.touched)">
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                <ng-container *ngIf="registerForm.get('email')?.hasError('required')">
                  Please enter your email
                </ng-container>
              </div>
            </ng-container>
          </div>

          <div class="mb-3 form-password-toggle fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <div class="input-group input-group-merge">
              <div class="form-floating form-floating-outline">
                <input
                  [type]="passwordVisibility.password ? 'text' : 'password'"
                  id="password" class="form-control" formControlName="password"
                       placeholder="············" aria-describedby="password" #passwordElement
                       [ngClass]="{'is-invalid': registerForm.get('password')?.invalid && (!registerForm.get('password')?.pristine || registerForm.get('password')?.touched) }"
                >
                <label for="password">Password</label>
              </div>
              <span role="button"  class="input-group-text cursor-pointer" (click)="togglePasswordVisibility('password')">
                <i class="ri-20px" [ngClass]="{'ri-eye-line': passwordVisibility.password,'ri-eye-off-line': !passwordVisibility.password}"></i>
              </span>
            </div>
            <ng-container
              *ngIf="registerForm.get('password')?.invalid && (!registerForm.get('password')?.pristine || registerForm.get('password')?.touched)">
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                <ng-container *ngIf="registerForm.get('password')?.hasError('required')">
                  Please enter your password
                </ng-container>
              </div>
            </ng-container>
          </div>

          <div class="mb-3 form-password-toggle fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <div class="input-group input-group-merge">
              <div class="form-floating form-floating-outline">
                <input [type]="passwordVisibility.otp ? 'text' : 'password'"
                       id="otp" class="form-control" formControlName="otp"
                       placeholder="Enter OTP" aria-describedby="OTP" #otpElement
                       [ngClass]="{'is-invalid': registerForm.get('otp')?.invalid && (!registerForm.get('otp')?.pristine || registerForm.get('otp')?.touched)}"
                >
                <label for="otp">OTP</label>
              </div>
              <span role="button" class="input-group-text cursor-pointer" (click)="togglePasswordVisibility('otp')">
                <i class="ri-20px" [ngClass]="{'ri-eye-line': passwordVisibility.otp,'ri-eye-off-line': !passwordVisibility.otp}"></i>
              </span>
            </div>
            <ng-container
              *ngIf="registerForm.get('otp')?.invalid && (!registerForm.get('otp')?.pristine || registerForm.get('otp')?.touched)">
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                <ng-container *ngIf="registerForm.get('otp')?.hasError('required')">
                  Please enter One Time Password
                </ng-container>
              </div>
            </ng-container>
          </div>

          <div class="mb-5 py-2 fv-plugins-icon-container">
            <div class="form-check mb-0">
              <input class="form-check-input" type="checkbox" id="terms-conditions" formControlName="terms"
                     [ngClass]="{'is-invalid': registerForm.get('terms')?.invalid && (!registerForm.get('terms')?.pristine || registerForm.get('terms')?.touched)}"
              >
              <label class="form-check-label" for="terms-conditions">
                I agree to
                <a href="javascript:void(0);" role="link">privacy policy &amp; terms</a>
              </label>
              <ng-container
                *ngIf="registerForm.get('terms')?.invalid && (!registerForm.get('terms')?.pristine || registerForm.get('terms')?.touched)">
                <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                  <ng-container *ngIf="registerForm.get('terms')?.hasError('required')">
                    Please agree terms & conditions
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>

          <button class="btn btn-primary d-grid w-100 waves-effect waves-light" type="submit"
                  [disabled]="loading || registerForm.invalid">
            Sign up
          </button>
        </form>

        <p class="text-center mb-5">
          <span>Already have an account?</span>
          <a routerLink="/login">
            <span>Sign in instead</span>
          </a>
        </p>

        <div class="divider my-5">
          <div class="divider-text">or</div>
        </div>

        <div class="d-flex justify-content-center gap-2">
          <a href="javascript:;" class="btn btn-icon btn-lg rounded-pill btn-text-google-plus waves-effect waves-light">
            <i class="tf-icons ri-google-fill ri-24px"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- /Register -->
  </div>
</div>
