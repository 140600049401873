import {AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {CommonModule, DOCUMENT} from "@angular/common";
import PerfectScrollbar from "perfect-scrollbar";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollable', { static: true }) scrollable!: ElementRef;

  public availableThemes: ThemeOption[] = [
    {dataTheme: 'light', displayName: 'Light'},
    {dataTheme: 'dark', displayName: 'Dark'},
    {dataTheme: 'system', displayName: 'System'},
  ];

  public selectedTheme: string = 'light';
  public layoutMenuClass = 'layout-menu-expanded';

  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private document: Document,private router:Router) {}

  ngOnInit() {
    try {
      const storedTheme = localStorage.getItem('storedTheme');
      if (storedTheme && this.availableThemes.some(theme => theme.dataTheme === storedTheme)) {
        this.selectedTheme = storedTheme;
      }
    } catch (error) {
      console.warn('Error retrieving theme from localStorage:', error);
    }
  }

  ngAfterViewInit() {
    console.log('this.scrollable.nativeElement',this.scrollable.nativeElement)
    new PerfectScrollbar(this.scrollable.nativeElement, {
      wheelPropagation: false
    });
  }

  handleMenuToggle() {
    const htmlElement = this.document.documentElement;

    if (htmlElement.classList.contains(this.layoutMenuClass)) {
      this.renderer2.removeClass(htmlElement, this.layoutMenuClass);
    } else {
      this.renderer2.addClass(htmlElement, this.layoutMenuClass);
    }
  }

  setTheme(theme: string) {
    console.log('i am clicked', theme)
    this.selectedTheme = theme;
    localStorage.setItem('storedTheme', theme);
  }

  getIcon(theme: string): string {
    switch (theme) {
      case 'light':
        return 'weather-sunny';
      case 'dark':
        return 'weather-night';
      case 'system':
        return 'monitor';
      default:
        return ''; // Or a default icon
    }
  }
  logout(){
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
    this.router.navigate(['/login'])
  }


}

interface ThemeOption {
  dataTheme: string;
  displayName: string;
}
