import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string = 'https://authorization.mynextsalon.com';

  constructor(private http: HttpClient) {
  }

  registerUser(payload: any): Observable<any> {
    return this.http.post(this.baseUrl + '/auth/signup', payload);
  }

  loginUser(payload: any): Observable<any> {
    return this.http.post(this.baseUrl + '/auth/login', payload);
  }
}
