<div class="position-relative">
  <div class="authentication-wrapper authentication-cover">
    <!-- Logo -->
    <a href="index.html" class="auth-cover-brand d-flex align-items-center gap-3">
      <span class="app-brand-logo">
<span style="color:var(--bs-primary);">
   <img src="assets/img/logo/true-invoice-high-resolution-logo.png" alt="" width="30" height="30"
        class="rounded-2">
</span>
</span>
      <span class="app-brand-text text-heading fw-semibold">True Invoice</span>
    </a>
    <!-- /Logo -->
    <div class="authentication-inner row m-0">

      <!-- /Left Section -->
      <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
        <div>
          <img src="assets/img/illustrations/auth-cover-reset-password-illustration-light.png"
               class="authentication-image-model d-none d-lg-block" alt="auth-model"
               data-app-light-img="illustrations/auth-cover-reset-password-illustration-light.png"
               data-app-dark-img="illustrations/auth-cover-reset-password-illustration-dark.png">
        </div>
        <img src="assets/img/illustrations/tree-3.png" alt="tree" class="authentication-image-tree z-n1">
        <img src="assets/img/illustrations/auth-cover-mask-light.png"
             class="scaleX-n1-rtl authentication-image d-none d-lg-block w-75" height="362" alt="triangle-bg"
             data-app-light-img="illustrations/auth-cover-mask-light.png"
             data-app-dark-img="illustrations/auth-cover-mask-dark.png">
      </div>
      <!-- /Left Section -->

      <!-- Reset Password -->
      <div
        class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-5 px-12 py-4">
        <div class="w-px-400 mx-auto pt-5 pt-lg-0">
          <h4 class="mb-1">Reset Password 🔒</h4>
          <p class="mb-5">Your new password must be different from previously used passwords</p>
          <form id="formAuthentication" class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                action="/login" method="GET" novalidate="novalidate">
            <div class="mb-5 form-password-toggle fv-plugins-icon-container">
              <div class="input-group input-group-merge">
                <div class="form-floating form-floating-outline">
                  <input type="password" id="password" class="form-control" name="password" placeholder="············"
                         aria-describedby="password">
                  <label for="password">New Password</label>
                </div>
                <span class="input-group-text cursor-pointer"><i class="ri-eye-off-line ri-20px"></i></span>
              </div>
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
            </div>
            <div class="mb-5 form-password-toggle fv-plugins-icon-container">
              <div class="input-group input-group-merge">
                <div class="form-floating form-floating-outline">
                  <input type="password" id="confirm-password" class="form-control" name="confirm-password"
                         placeholder="············" aria-describedby="password">
                  <label for="confirm-password">Confirm Password</label>
                </div>
                <span class="input-group-text cursor-pointer"><i class="ri-eye-off-line ri-20px"></i></span>
              </div>
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
            </div>
            <button class="btn btn-primary d-grid w-100 mb-5 waves-effect waves-light"
            >
              Set new password
            </button>
            <div class="text-center">
              <a routerLink="/login" class="d-flex align-items-center justify-content-center">
                <i class="ri-arrow-left-s-line scaleX-n1-rtl ri-20px me-1_5"></i>
                Back to login
              </a>
            </div>
            <input type="hidden"></form>
        </div>
      </div>
      <!-- /Reset Password -->
    </div>
  </div>
</div>
