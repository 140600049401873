<nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
     id="layout-navbar">
  <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
    <button role="button" class="nav-item nav-link px-0 me-xl-4" (click)="handleMenuToggle()">
      <i class="mdi mdi-menu mdi-24px"></i>
    </button>
  </div>

  <div class="navbar-nav-right d-flex align-items-center justify-content-between" id="navbar-collapse">
    <!-- Search -->
    <div class="navbar-nav align-items-center">
      <div class="nav-item d-flex align-items-center">
        <i class="mdi mdi-magnify mdi-24px lh-0"></i>
        <input
          type="text"
          class="form-control border-0 shadow-none bg-body"
          placeholder="Search..."
          aria-label="Search..."/>
      </div>
    </div>
    <!-- /Search -->

    <ul class="navbar-nav flex-row align-items-center">
      <!-- Place this tag where you want the button to render. -->
      <li class="nav-item dropdown-style-switcher dropdown me-2 me-xl-1">
        <a
          class="nav-link btn btn-text-secondary rounded-pill btn-icon dropdown-toggle hide-arrow waves-effect waves-light"
          data-bs-toggle="dropdown">
          <i class="mdi mdi-24px mdi-weather-night"></i>
        </a>
        <ul class="dropdown-menu dropdown-menu-end dropdown-styles">
          <li *ngFor="let theme of availableThemes">
            <a class="dropdown-item waves-effect" (click)="setTheme(theme.dataTheme)"
               [ngClass]="{'active': selectedTheme === theme.dataTheme}">
              <span class="align-middle"><i
                class="mdi mdi-{{ getIcon(theme.dataTheme) }} me-2"></i>{{ theme.displayName }}</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item dropdown-notifications navbar-dropdown dropdown me-2 me-xl-1">
        <a class="nav-link btn btn-text-secondary rounded-pill btn-icon dropdown-toggle hide-arrow waves-effect waves-light" href="javascript:void(0);" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
          <i class="mdi mdi-bell-outline mdi-24px"></i>
          <span class="position-absolute top-0 start-50 translate-middle-y badge badge-dot bg-danger mt-2 border"></span>
        </a>
        <ul class="dropdown-menu dropdown-menu-end py-0" data-bs-popper="static">
          <li class="dropdown-menu-header border-bottom">
            <div class="dropdown-header d-flex align-items-center py-3">
              <h6 class="fw-normal mb-0 me-auto">Notification</h6>
              <span class="badge rounded-pill bg-label-primary">8 New</span>
            </div>
          </li>
          <li class="dropdown-notifications-list scrollable-container" #scrollable>
            <ul class="list-group list-group-flush">
              <li class="list-group-item list-group-item-action dropdown-notifications-item waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <img src="assets/img/avatars/1.png" alt="" class="w-px-40 h-auto rounded-circle">
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1 text-truncate">Congratulation Lettie 🎉</h6>
                    <small class="text-truncate text-body">Won the monthly best seller gold badge</small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">1h ago</small>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action dropdown-notifications-item waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <span class="avatar-initial rounded-circle bg-label-danger">CF</span>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1 text-truncate">Charles Franklin</h6>
                    <small class="text-truncate text-body">Accepted your connection</small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">12hr ago</small>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <img src="assets/img/avatars/2.png" alt="" class="w-px-40 h-auto rounded-circle">
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1 text-truncate">New Message ✉️</h6>
                    <small class="text-truncate text-body">You have new message from Natalie</small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">1h ago</small>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action dropdown-notifications-item waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <span class="avatar-initial rounded-circle bg-label-success"><i class="mdi mdi-cart-outline"></i></span>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1 text-truncate">Whoo! You have new order 🛒 </h6>
                    <small class="text-truncate text-body">ACME Inc. made new order $1,154</small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">1 day ago</small>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <img src="assets/img/avatars/4.png" alt="" class="w-px-40 h-auto rounded-circle">
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1 text-truncate">Application has been approved 🚀 </h6>
                    <small class="text-truncate text-body">Your ABC project application has been approved.</small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">2 days ago</small>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <span class="avatar-initial rounded-circle bg-label-success"><i class="mdi mdi-chart-pie-outline"></i></span>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1 text-truncate">Monthly report is generated</h6>
                    <small class="text-truncate text-body">July monthly financial report is generated </small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">3 days ago</small>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <img src="assets/img/avatars/5.png" alt="" class="w-px-40 h-auto rounded-circle">
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1 text-truncate">Send connection request</h6>
                    <small class="text-truncate text-body">Peter sent you connection request</small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">4 days ago</small>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action dropdown-notifications-item waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <img src="assets/img/avatars/6.png" alt="" class="w-px-40 h-auto rounded-circle">
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1 text-truncate">New message from Jane</h6>
                    <small class="text-truncate text-body">Your have new message from Jane</small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">5 days ago</small>
                  </div>
                </div>
              </li>
              <li class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read waves-effect">
                <div class="d-flex align-items-center gap-2">
                  <div class="flex-shrink-0">
                    <div class="avatar me-1">
                      <span class="avatar-initial rounded-circle bg-label-warning"><i class="mdi mdi-alert-circle-outline"></i></span>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 overflow-hidden w-px-250">
                    <h6 class="mb-1">CPU is running high</h6>
                    <small class="text-truncate text-body">CPU Utilization Percent is currently at 88.63%,</small>
                  </div>
                  <div class="flex-shrink-0 dropdown-notifications-actions">
                    <small class="text-muted">5 days ago</small>
                  </div>
                </div>
              </li>
            </ul>
            <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></li>
          <li class="dropdown-menu-footer border-top p-3">
            <a href="javascript:void(0);" class="btn btn-primary d-flex justify-content-center waves-effect waves-light">Read all notifications</a>
          </li>
        </ul>
      </li>


      <!-- User -->
      <li class="nav-item navbar-dropdown dropdown-user dropdown">
        <a
          class="nav-link dropdown-toggle hide-arrow p-0"

          data-bs-toggle="dropdown">
          <div class="avatar avatar-online">
            <img src="../assets/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle"/>
          </div>
        </a>
        <ul class="dropdown-menu dropdown-menu-end mt-3 py-2">
          <li>
            <a class="dropdown-item pb-2 mb-1">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 me-2 pe-1">
                  <div class="avatar avatar-online">
                    <img src="../assets/img/avatars/1.png" alt class="w-px-40 h-auto rounded-circle"/>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-0">Abhishek Yadav</h6>
                  <small class="text-muted">Admin</small>
                </div>
              </div>
            </a>
          </li>
          <li>
            <div class="dropdown-divider my-1"></div>
          </li>
          <li>
            <a class="dropdown-item">
              <i class="mdi mdi-account-outline me-1 mdi-20px"></i>
              <span class="align-middle">My Profile</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item">
              <i class="mdi mdi-cog-outline me-1 mdi-20px"></i>
              <span class="align-middle">Settings</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item">
                        <span class="d-flex align-items-center align-middle">
                          <i class="flex-shrink-0 mdi mdi-credit-card-outline me-1 mdi-20px"></i>
                          <span class="flex-grow-1 align-middle ms-1">Billing</span>
                          <span class="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                        </span>
            </a>
          </li>
          <li>
            <div class="dropdown-divider my-1"></div>
          </li>
          <li>
            <a class="dropdown-item" (click)="logout()">
              <i class="mdi mdi-power me-1 mdi-20px"></i>
              <span class="align-middle">Log Out</span>
            </a>
          </li>
        </ul>
      </li>
      <!--/ User -->
    </ul>
  </div>
</nav>
