import {Routes} from '@angular/router';
import {HomeComponent} from "./components/layout/home/home.component";
import {LoginComponent} from "./components/authentication/login/login.component";
import {NotFoundComponent} from "./components/authentication/not-found/not-found.component";
import {MaintenanceComponent} from "./components/authentication/maintenance/maintenance.component";
import {ForgotPasswordComponent} from "./components/authentication/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./components/authentication/reset-password/reset-password.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {RegisterCoverComponent} from "./components/authentication/register-cover/register-cover.component";
import {StoreSwitcherComponent} from "./components/store-switcher/store-switcher.component";
import {UsersComponent} from "./components/users/users.component";
import {RolePermissionComponent} from "./components/role-permission/role-permission.component";

export const routes: Routes = [
  {
    path: '', component: HomeComponent,
    children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'users', component: UsersComponent},
      {path: 'roles', component: RolePermissionComponent},
    ]
  }, // Default route for root path
  {path: 'register', component: RegisterCoverComponent},
  {path: 'login', component: LoginComponent},
  {path: 'select-store', component: StoreSwitcherComponent},
  {path: 'maintenance', component: MaintenanceComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: '**', component: NotFoundComponent}
];
