<div class="authentication-wrapper authentication-cover">
  <!-- Logo -->
  <a href="index.html" class="auth-cover-brand d-flex align-items-center gap-3">
    <span class="app-brand-logo">
<span style="color:var(--bs-primary);">
   <img src="assets/img/logo/true-invoice-high-resolution-logo.png" alt="" width="30" height="30"
        class="rounded-2">
</span>
</span>
    <span class="app-brand-text text-heading fw-semibold">True Invoice</span>
  </a>
  <!-- /Logo -->
  <div class="authentication-inner row m-0">

    <!-- /Left Section -->
    <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
      <div>
        <img src="assets/img/illustrations/auth-cover-forgot-password-illustration-light.png"
             class="authentication-image-model d-none d-lg-block" alt="auth-model"
             data-app-light-img="illustrations/auth-cover-forgot-password-illustration-light.png"
             data-app-dark-img="illustrations/auth-cover-forgot-password-illustration-dark.png">
      </div>
      <img src="assets/img/illustrations/tree.png" alt="tree" class="authentication-image-tree z-n1">
      <img src="assets/img/illustrations/auth-cover-mask-light.png"
           class="scaleX-n1-rtl authentication-image d-none d-lg-block w-75" height="362" alt="triangle-bg"
           data-app-light-img="illustrations/auth-cover-mask-light.png"
           data-app-dark-img="illustrations/auth-cover-mask-dark.png">
    </div>
    <!-- /Left Section -->

    <!-- Forgot Password -->
    <div class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-12 p-6">
      <div class="w-px-400 mx-auto">
        <h4 class="mb-1">Forgot Password? 🔒</h4>
        <p class="mb-5">Enter your email and we'll send you instructions to reset your password</p>
        <form id="formAuthentication" class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework"
              action="/reset-password" method="GET" novalidate="novalidate">
          <div class="form-floating form-floating-outline mb-5 fv-plugins-icon-container">
            <input type="text" class="form-control" id="email" name="email" placeholder="Enter your email" autofocus="">
            <label for="email">Email</label>
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
          </div>
          <button class="btn btn-primary d-grid w-100 mb-5 waves-effect waves-light">
            Send Reset Link
          </button>
          <input type="hidden">
        </form>

        <div class="text-center">
          <a routerLink="/login" class="d-flex align-items-center justify-content-center">
            <i class="ri-arrow-left-s-line scaleX-n1-rtl ri-20px me-1_5"></i>
            Back to login
          </a>
        </div>
      </div>
    </div>
    <!-- /Forgot Password -->
  </div>
</div>
