import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UsersService} from "../../services/users.service";
import {CommonModule} from "@angular/common";
import {RolesService} from "../../services/roles.service";
import {MasterService} from "../../services/master.service";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, NgxSpinnerComponent
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit, AfterViewInit {
  accessToken = localStorage.getItem('access') || '';
  public usersList: any[] = [];
  public loading: boolean = true;
  public roles: any[] = [];
  public genders: any[] = [];
  public countries: any[] = [];
  public states: any[] = [];
  public cities: any[] = [];
  public userForm:FormGroup;
  @ViewChild('closeButton') closeButton!: ElementRef;
  @ViewChild('addUserButton') addUserButton!: ElementRef;

  constructor(private userService: UsersService, private roleService: RolesService, private masterService: MasterService,
              private fb: FormBuilder,private spinner: NgxSpinnerService) {
    this.userForm = this.fb.group({
      key: new FormControl(''),
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      dob: new FormControl('', Validators.required),
      doa: new FormControl()
    })
  }

  ngOnInit() {
    this.fetchRoles();
    this.fetchUsers();
    this.fetchCountries();
    this.fetchGender('gender');
  }
  ngAfterViewInit() {
   const table = document.querySelector('#usersTable');
   // @ts-ignore
    table?.dataTable();
  }

  handleCountryChange() {
    this.fetchStates('92Q39XSAIX');
  }

  handleStateChange() {
    this.fetchCities('92Q39XSAIX')
  }


  fetchUsers() {
    this.loading = true;
    this.userService.getUsers(this.accessToken).subscribe({
      next: (response: any) => {
        this.usersList = response.data;
        this.loading = false;
      },
      error: err => {
        console.log('hello', err)
        this.loading = false
      }
    })
  }

  fetchRoles() {
    this.roleService.getRoles(this.accessToken).subscribe({
      next: (res: any) => {
        this.roles = res?.data
        this.loading = false
      },
      error: err => {
        this.loading = false;
      }
    })
  }

  fetchCountries() {
    this.masterService.getCountries(this.accessToken).subscribe({
      next: (res: any) => {
        this.countries = res?.data
        this.loading = false
      },
      error: err => {
        this.loading = false;
      }
    })
  }

  fetchStates(country: string) {
    this.masterService.getStates(this.accessToken, country).subscribe({
      next: (res: any) => {
        this.states = res?.data
        this.loading = false
      },
      error: err => {
        this.loading = false;
      }
    })
  }

  fetchCities(country: string, state: string='HOCO9BTR5H') {
    this.masterService.getCities(this.accessToken, country, state).subscribe({
      next: (res: any) => {
        this.cities = res?.data
        this.loading = false
      },
      error: err => {
        this.loading = false;
      }
    })
  }

  fetchGender(name: string) {
    this.masterService.lookup(this.accessToken, name).subscribe({
      next: (res: any) => {
        this.genders = res?.data
        this.loading = false
      },
      error: err => {
        this.loading = false;
      }
    })
  }

  handleAddUserForm() {
    const payload = this.userForm.getRawValue();
    this.userForm.disable();
    this.spinner.show('userForm');
    if (this.userForm.get('key')?.value) {
      this.userService.updateUser(this.accessToken, payload).subscribe({
        next: (res: any) => {
          this.loading = false
          console.log('response:', res);
          this.userForm.enable();
          this.userForm.reset();
          this.userForm.updateValueAndValidity();
          this.spinner.hide('userForm');
          this.closeOffcanvas();
          this.fetchUsers()
        },
        error: err => {
          this.userForm.enable();
          this.loading = false;
          this.spinner.hide('userForm');
        }
      })

    } else {
      this.userService.addUser(this.accessToken, payload).subscribe({
        next: (res: any) => {
          this.loading = false
          console.log('response:', res);
          this.userForm.enable();
          this.userForm.reset();
          this.userForm.updateValueAndValidity();
          this.closeOffcanvas();
          this.spinner.hide('userForm');
          this.fetchUsers()
        },
        error: err => {
          this.userForm.enable();
          this.loading = false;
          this.spinner.hide('userForm');
        }
      })
    }
  }
  closeOffcanvas() {
    this.closeButton.nativeElement.click();
    this.userForm.reset();
    this.userForm.updateValueAndValidity();
  }

  openEditPopup(user: any) {
    this.userService.getUser(this.accessToken, user.key).subscribe({
      next: (res: any) => {
        this.loading = false
        console.log('response:', res?.data);
        this.userForm.patchValue(res?.data);
        this.addUserButton.nativeElement.click();
      },
      error: err => {
        this.loading = false;
      }
    })
  }
}
