import {Component, HostListener, OnInit, Renderer2} from '@angular/core';
import {MenuService} from "../../../services/menu.service";
import {CommonModule} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, RouterLink, NgxSpinnerComponent],
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent implements OnInit {
  public menuList: any[] = [];
  private isLayoutFixed: boolean = true;
  private accessToken = window.localStorage.getItem('access') || '';

  constructor(private renderer: Renderer2, private menuService: MenuService,private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.fetchSideMenu();
  }

  toggleLayoutFixed() {
    console.log('clicked');
    const htmlElement = document.documentElement;

    if (this.isLayoutFixed) {
      this.renderer.addClass(htmlElement, 'layout-menu-collapsed');
      // this.renderer.removeClass(htmlElement, 'layout-menu-fixed');
    } else {
      this.renderer.removeClass(htmlElement, 'layout-menu-collapsed');
      // this.renderer.addClass(htmlElement, 'layout-menu-fixed');
    }

    // Toggle the state
    this.isLayoutFixed = !this.isLayoutFixed;
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.onHover();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.onHoverEnd();
  }

  onHover() {
    const htmlElement = document.documentElement;
    this.renderer.addClass(htmlElement, 'layout-menu-hover');
  }

  onHoverEnd() {
    const htmlElement = document.documentElement;
    this.renderer.removeClass(htmlElement, 'layout-menu-hover');
  }

  fetchSideMenu() {
    this.spinner.show('sidemenu');
    this.menuService.getMenu(this.accessToken).subscribe({
      next: (response: any) => {
        console.log('menu', response?.data);
        this.menuList = response?.data;
        this.spinner.hide('sidemenu');
      },
      error: err => {
        this.spinner.hide('sidemenu');
      }
    })
  }

  getCorrectRoute(route: string): string {
    if (route === '/') {
      return '/dashboard'; // Map root route to /dashboard
    }
    return route;
  }
}
