import { Component, OnInit } from '@angular/core';
import { RolesService } from "../../services/roles.service";
import { CommonModule } from "@angular/common";
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-role-permission',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './role-permission.component.html',
  styleUrl: './role-permission.component.scss'
})
export class RolePermissionComponent implements OnInit {
  public loading: boolean = true;
  public roles: any[] = [];
  accessToken = window.localStorage.getItem('access') || '';
  public form!: FormGroup;
  public menus!: FormArray;
  constructor(private fb: FormBuilder, private roleService: RolesService) {
  }
  ngOnInit() {
   this.fetchRoles();
    this.createNativeForm()
    this.getRoles();
  }

  fetchRoles() {
    this.roleService.getRoles(this.accessToken).subscribe({
      next: (res: any) => {
        this.roles = res?.data
        this.loading = false
      },
      error: err => {
        this.loading = false;
      }
    })
  }
  public role: any;
  getRoles() {
    let i = 0;
    this.role = this.roleData?.data?.menus
    const menus = this.form?.get('menus') as FormArray;
    this.role.forEach((menu: any) => {
      let frm = this.initiateForm(this.metaData?.controls[0]?.controls)
      frm.patchValue(menu)
      frm.updateValueAndValidity();
      menus?.push(frm);
      console.log('menus', menus)

      menus.updateValueAndValidity();
      i++
    });
  }
  createNativeForm() {
    this.form = this.fb.group({
      menus: this.fb.array([])
    });
  }

  initiateForm(metaData: any) {
    let controlsObj: any = {};
    metaData?.forEach((ele: any) => {
      controlsObj[ele?.name] = new FormControl(null)
    });
    return this.fb.group(controlsObj);
  }

  get getControls() {
    const questions = this.form?.get('menus') as FormArray;
    return questions;
  }

  public formData: any;
  getValue() {
    this.formData = this.form.getRawValue();
  }
  
  isVisible(val: any) {
    return !JSON.parse(val);
  }

  roleData = {
    "msg_code": [],
    "data": {
      "name": "admin",
      "parent": null,
      "menus": [
        {
          "key": "293UKMQ3NU",
          "name": "Dashboard",
          "is_read": false,
          "is_create": true,
          "is_update": true,
          "is_delete": true
        },
        {
          "key": "RFFK4JBK28",
          "name": "Users",
          "is_read": true,
          "is_create": true,
          "is_update": false,
          "is_delete": true
        },
        {
          "key": "CRLEK6P17S",
          "name": "Roles",
          "is_read": true,
          "is_create": false,
          "is_update": true,
          "is_delete": true
        },
        {
          "key": "5IX6AVM6Y5",
          "name": "Stores",
          "is_read": true,
          "is_create": true,
          "is_update": false,
          "is_delete": true
        },
        {
          "key": "X4WZ4ZQ8SN",
          "name": "My Profile",
          "is_read": true,
          "is_create": true,
          "is_update": true,
          "is_delete": true
        },
        {
          "key": "YV8Y3G9DF1",
          "name": "Change Password",
          "is_read": true,
          "is_create": false,
          "is_update": true,
          "is_delete": true
        }
      ]
    },
    "r_id": "0e749c7c-692d-11ef-8cdc-0242ac120002",
    "status": 200
  }
  metaData: any = {
    "name": "role_menu",
    "controls": [
      {
        "div": "col-12 col-xs-12 col-md-12 col-lg-12",
        "name": "menus",
        "label": "Menus",
        "type": "formgroup",
        "is_visible": true,
        "datasource": {
          "url": "http://82.112.227.207:8000/api/menu/list",
          "method": "GET",
          "payload": {},
          "response": {
            "key": "",
            "name": "",
            "is_read": false,
            "is_create": false,
            "is_update": false,
            "is_delete": false
          }
        },
        "controls": [
          {
            "div": "",
            "class": "form-control",
            "name": "key",
            "type": "text",
            "is_visible": false,
            "is_readonly": true,
            "is_required": true
          },
          {
            "div": "",
            "class": "form-control",
            "name": "name",
            "label": "Menu Name",
            "type": "label",
            "is_visible": true,
            "is_readonly": true,
            "is_required": false
          },
          {
            "div": "form-check mb-0 me-4 me-lg-12",
            "class": "form-check-input",
            "name": "is_read",
            "label": "Read",
            "type": "checkbox",
            "is_visible": true,
            "is_readonly": false,
            "is_required": false
          },
          {
            "div": "form-check mb-0 me-4 me-lg-12",
            "class": "form-check-input",
            "name": "is_create",
            "label": "Create",
            "type": "checkbox",
            "is_visible": true,
            "is_readonly": false,
            "is_required": false
          },
          {
            "div": "form-check mb-0 me-4 me-lg-12",
            "class": "form-check-input",
            "name": "is_update",
            "label": "Update",
            "type": "checkbox",
            "is_visible": true,
            "is_readonly": false,
            "is_required": false
          },
          {
            "div": "form-check mb-0",
            "class": "form-check-input",
            "name": "is_delete",
            "label": "Delete",
            "type": "checkbox",
            "is_visible": true,
            "is_readonly": false,
            "is_required": false
          }
        ]
      }
    ]
  }
  
}
