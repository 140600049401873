import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private baseUrl: string = 'https://authorization.mynextsalon.com';

  constructor(private http: HttpClient) {
  }

  getUsers(token:string){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(this.baseUrl + '/api/user', { headers });
  }

  getUser(token:string,userKey:string){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(this.baseUrl + '/api/user?key=' + userKey, {headers});
  }



  addUser(token: string, payload: any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(this.baseUrl + '/api/user', payload, {headers});
  }
  updateUser(token: string, payload: any) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.patch(this.baseUrl + '/api/user', payload, {headers});
  }
}
