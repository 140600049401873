<div class="row g-6 mb-6">
  <div class="col-sm-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="me-1">
            <p class="text-heading mb-1">Session</p>
            <div class="d-flex align-items-center">
              <h4 class="mb-1 me-2">21,459</h4>
              <p class="text-success mb-1">(+29%)</p>
            </div>
            <small class="mb-0">Total Users</small>
          </div>
          <div class="avatar">
            <div class="avatar-initial bg-label-primary rounded">
              <div class="ri-group-line ri-26px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="me-1">
            <p class="text-heading mb-1">Paid Users</p>
            <div class="d-flex align-items-center">
              <h4 class="mb-1 me-2">4,567</h4>
              <p class="text-success mb-1">(+18%)</p>
            </div>
            <small class="mb-0">Last week analytics</small>
          </div>
          <div class="avatar">
            <div class="avatar-initial bg-label-danger rounded">
              <div class="ri-user-add-line ri-26px scaleX-n1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="me-1">
            <p class="text-heading mb-1">Active Users</p>
            <div class="d-flex align-items-center">
              <h4 class="mb-1 me-2">19,860</h4>
              <p class="text-danger mb-1">(-14%)</p>
            </div>
            <small class="mb-0">Last week analytics</small>
          </div>
          <div class="avatar">
            <div class="avatar-initial bg-label-success rounded">
              <div class="ri-user-follow-line ri-26px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="me-1">
            <p class="text-heading mb-1">Pending Users</p>
            <div class="d-flex align-items-center">
              <h4 class="mb-1 me-2">237</h4>
              <p class="text-success mb-1">(+42%)</p>
            </div>
            <small class="mb-0">Last week analytics</small>
          </div>
          <div class="avatar">
            <div class="avatar-initial bg-label-warning rounded">
              <div class="ri-user-search-line ri-26px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="card">
  <div class="card-header border-bottom">
    <h6 class="card-title mb-0">Filters</h6>
    <div class="d-flex justify-content-between align-items-center row pt-4 pb-2 gap-4 gap-md-0 gx-5">
      <div class="col-md-4 user_role">
        <select class="form-select text-capitalize" id="UserRole">
          <option value=""> Select Role</option>
          @for (role of roles; track role.key) {
            <option [value]="role.key"> {{ role.name }}</option>
          }
        </select>
      </div>
      <div class="col-md-4 user_status">
        <select class="form-select text-capitalize" id="FilterTransaction">
        <option value=""> Select Status</option>
        <option class="text-capitalize" value="Pending">Pending</option>
        <option class="text-capitalize" value="Active">Active</option>
        <option class="text-capitalize" value="Inactive">Inactive</option>
      </select></div>
    </div>
  </div>

  <div class="card-datatable table-responsive">
    <div class="dataTables_wrapper dt-bootstrap5 no-footer" id="DataTables_Table_0_wrapper">
      <div class="row mx-1">
        <div class="col-md-2 d-flex align-items-center justify-content-md-start justify-content-center ps-4">
          <div class="dt-action-buttons mt-4 mt-md-0">
            <div class="dt-buttons btn-group flex-wrap">
              <div class="btn-group">
                <button
                  aria-controls="DataTables_Table_0"
                  aria-expanded="false" aria-haspopup="dialog"
                  class="btn btn-secondary buttons-collection dropdown-toggle btn-outline-secondary me-4 waves-effect waves-light"
                  tabindex="0"
                  type="button"><span><i class="ri-download-line ri-16px me-1"></i> <span
                  class="d-none d-sm-inline-block">Export</span></span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-10">
          <div class="d-flex align-items-center justify-content-md-end justify-content-center">
            <div class="me-4">
              <div class="dataTables_filter" id="DataTables_Table_0_filter">
                <label>
                  <input aria-controls="DataTables_Table_0" class="form-control form-control-sm"
                         placeholder="Search User" type="search">
                </label>
              </div>
            </div>
            <div class="add-new">
              <button class="btn btn-primary waves-effect waves-light" data-bs-target="#offcanvasAddUser" #addUserButton
                      data-bs-toggle="offcanvas"><i
                class="ri-add-line me-0 me-sm-1 d-inline-block d-sm-none"></i><span class="d-none d-sm-inline-block"> Add New User </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table aria-describedby="DataTables_Table_0_info" class="datatables-users table dataTable no-footer dtr-column"
               id="usersTable" style="width: 1207px;">
          <thead>
          <tr>
            <th aria-label="" class="control sorting_disabled dtr-hidden" colspan="1" rowspan="1"
                style="width: 0; display: none;"></th>
            <th aria-label="" class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" colspan="1"
                data-col="1" rowspan="1" style="width: 18px;">
              <input class="form-check-input"  type="checkbox"></th>
            <th aria-controls="DataTables_Table_0" aria-label="User: activate to sort column ascending"
                aria-sort="descending" class="sorting sorting_desc" colspan="1"
                rowspan="1" style="width: 227px;" tabindex="0">User
            </th>
            <th aria-controls="DataTables_Table_0" aria-label="Email: activate to sort column ascending" class="sorting"
                colspan="1" rowspan="1"
                style="width: 265px;" tabindex="0">Email
            </th>
            <th aria-controls="DataTables_Table_0" aria-label="Email: activate to sort column ascending" class="sorting"
                colspan="1" rowspan="1"
                style="width: 265px;" tabindex="0">Phone
            </th>
            <th aria-controls="DataTables_Table_0" aria-label="Role: activate to sort column ascending" class="sorting"
                colspan="1" rowspan="1"
                style="width: 114px;" tabindex="0">Role
            </th>
            <th aria-controls="DataTables_Table_0" aria-label="Plan: activate to sort column ascending" class="sorting"
                colspan="1" rowspan="1"
                style="width: 79px;" tabindex="0">Created
            </th>
            <th aria-controls="DataTables_Table_0" aria-label="Plan: activate to sort column ascending" class="sorting"
                colspan="1" rowspan="1"
                style="width: 79px;" tabindex="0">Updated
            </th>
            <th aria-controls="DataTables_Table_0" aria-label="Status: activate to sort column ascending"
                class="sorting"
                colspan="1" rowspan="1"
                style="width: 79px;" tabindex="0">Status
            </th>
            <th aria-controls="DataTables_Table_0" aria-label="Status: activate to sort column ascending"
                class="sorting"
                colspan="1" rowspan="1"
                style="width: 79px;" tabindex="0">Verified
            </th>
            <th aria-label="Actions" class="sorting_disabled" colspan="1" rowspan="1" style="width: 119px;">Actions</th>
          </tr>
          </thead>
          <tbody>
            @for (userRow of usersList; track userRow.key; let rowIndex = $index, e = $even) {
              <tr [ngClass]="e ? 'even' : 'odd'">
                <td class="  control" style="display: none;" tabindex="0">
                </td>
                <td class="  dt-checkboxes-cell">
                  <input class="dt-checkboxes form-check-input" type="checkbox"></td>
                <td class="sorting_1">
                  <div class="d-flex justify-content-start align-items-center user-name">
                    <div class="avatar-wrapper">
                      <div class="avatar avatar-sm me-4">
                        <img alt="Avatar" class="rounded-circle"  src="assets/img/avatars/2.png">
                      </div>
                    </div>
                    <div class="d-flex flex-column">
                      <a class="text-heading text-truncate">
                <span class="fw-medium">
                  {{userRow.name}}
                </span>
                      </a>
                      <small>
                        &#64;username
                      </small>
                    </div>
                  </div>
                </td>
                <td><span>{{userRow.email}}</span></td>
                <td><span>{{userRow.phone}}</span></td>
                <td>
            <span class="text-truncate d-flex align-items-center text-heading">
                @if (userRow.role === 'admin') {
                  <i class="ri-computer-line ri-22px text-danger me-2"></i>
                }
              @if (userRow.role === 'manager') {
                <i class="ri-edit-box-line ri-22px text-info me-2"></i>
              }
              @if (userRow.role === 'Region Manager') {
                <i class="ri-pie-chart-line ri-22px text-success me-2"></i>
              }
              @if (userRow.role === 'staff') {
                <i class="ri-user-line ri-22px text-primary me-2"></i>
              }
              {{ userRow.role | titlecase }}
          </span>
                </td>
                <td>
            <span class="text-heading">
              {{userRow.created_at}}
            </span>
                </td>
                <td>
            <span class="text-heading">
              {{userRow.updated_at}}
            </span>
                </td>
                <td>
                  @if (userRow.is_active) {
                    <span class="badge rounded-pill bg-label-success">Active</span>
                  }
                  @if (!userRow.is_active) {
                    <span class="badge rounded-pill bg-label-secondary">Inactive</span>
                  }
                </td>
                <td>
                  {{ userRow.is_verified ? 'Yes' : 'No' }}
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <a class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect delete-record"
                       data-bs-toggle="tooltip"
                       title="Delete Invoice">
                      <i class="ri-delete-bin-7-line ri-22px"></i>
                    </a>
                    <a class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect"
                       data-bs-toggle="tooltip" title="Preview">
                      <i class="ri-eye-line ri-22px"></i>
                    </a>
                    <button
                      class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect dropdown-toggle hide-arrow"
                      data-bs-toggle="dropdown"><i class="ri-more-2-line ri-22px"></i></button>
                    <div class="dropdown-menu dropdown-menu-end m-0">
                      <a class="dropdown-item">
                        <i class="ri-eye-line me-2"></i>
                        <span>View</span></a>
                      <button role="button" type="button" class="dropdown-item" (click)="openEditPopup(userRow)">
                        <i class="ri-edit-box-line me-2"></i>
                        <span>Edit</span>
                      </button>
                      <a class="dropdown-item delete-record">
                        <i class="ri-delete-bin-7-line me-2"></i>
                        <span>Delete</span>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="row mx-1">
        <div class="col-sm-12 col-md-6">
          <div aria-live="polite" class="dataTables_info" id="DataTables_Table_0_info" role="status">Showing 1 to 10 of
            50 entries
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
            <ul class="pagination">
              <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><a
                aria-controls="DataTables_Table_0" aria-disabled="true" class="page-link" data-dt-idx="previous"
                role="link"
                tabindex="-1">Previous</a></li>
              <li class="paginate_button page-item active"><a aria-controls="DataTables_Table_0" aria-current="page"
                                                              class="page-link"
                                                              data-dt-idx="0" href="#" role="link"
                                                              tabindex="0">1</a></li>
              <li class="paginate_button page-item "><a aria-controls="DataTables_Table_0" class="page-link"
                                                        data-dt-idx="1"
                                                        href="#" role="link" tabindex="0">2</a></li>
              <li class="paginate_button page-item "><a aria-controls="DataTables_Table_0" class="page-link"
                                                        data-dt-idx="2"
                                                        href="#" role="link" tabindex="0">3</a></li>
              <li class="paginate_button page-item "><a aria-controls="DataTables_Table_0" class="page-link"
                                                        data-dt-idx="3"
                                                        href="#" role="link" tabindex="0">4</a></li>
              <li class="paginate_button page-item "><a aria-controls="DataTables_Table_0" class="page-link"
                                                        data-dt-idx="4"
                                                        href="#" role="link" tabindex="0">5</a></li>
              <li class="paginate_button page-item next" id="DataTables_Table_0_next"><a
                aria-controls="DataTables_Table_0"
                class="page-link"
                data-dt-idx="next" href="#"
                role="link" tabindex="0">Next</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style="width: 1%;"></div>
    </div>
  </div>
  <!-- Offcanvas to add new user -->
  <div aria-labelledby="offcanvasAddUserLabel" class="offcanvas offcanvas-end" id="offcanvasAddUser" tabindex="-1"
  data-bs-backdrop="static"
  >
    <div class="offcanvas-header border-bottom">
      <h5 class="offcanvas-title" id="offcanvasAddUserLabel">
        <ng-container *ngIf="!userForm?.get('key')?.value">
          Add User
        </ng-container>
        <ng-container *ngIf="userForm.get('key')?.value">
          Update User
        </ng-container>
      </h5>
      <button aria-label="Close" class="btn-close text-reset" data-bs-dismiss="offcanvas" type="button"></button>
    </div>
    <div class="offcanvas-body mx-0 flex-grow-0 h-100">
      <form (ngSubmit)="handleAddUserForm()" [formGroup]="userForm"
            class="add-new-user pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="addNewUserForm"
            novalidate="novalidate">
        <div class="form-floating form-floating-outline mb-5 fv-plugins-icon-container">
          <input
            [ngClass]="{'is-invalid':userForm.get('name')?.invalid && (!userForm.get('name')?.pristine || userForm.get('name')?.touched)}"
            aria-label="First Name Last Name" class="form-control"
            formControlName="name"
            id="add-user-fullname"
            placeholder="First Name Last Name" type="text">
          <label for="add-user-fullname">Full Name</label>
          <ng-container
            *ngIf="userForm.get('name')?.invalid && (!userForm.get('name')?.pristine || userForm.get('name')?.touched)">
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              <ng-container *ngIf="userForm.get('name')?.hasError('required')">
                Please enter user Full Name
              </ng-container>
            </div>
          </ng-container>

        </div>
        <div class="form-floating form-floating-outline mb-5">
          <select
            [ngClass]="{'is-invalid':userForm.get('gender')?.invalid && (!userForm.get('gender')?.pristine || userForm.get('gender')?.touched)}"
            class="form-select" formControlName="gender"
            id="user-gender">
            <option value="">Select Gender</option>
            <option *ngFor="let gender of genders;" [value]="gender.key">{{gender.name | titlecase}}</option>
          </select>
          <label for="user-role">Gender</label>
          <ng-container
            *ngIf="userForm.get('gender')?.invalid && (!userForm.get('gender')?.pristine || userForm.get('gender')?.touched)">
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              <ng-container *ngIf="userForm.get('gender')?.hasError('required')">
                Gender is required
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="form-floating form-floating-outline mb-5 fv-plugins-icon-container">
          <input
            [ngClass]="{'is-invalid':userForm.get('dob')?.invalid && (!userForm.get('dob')?.pristine || userForm.get('dob')?.touched)}"
            aria-label="Date of birth" class="form-control"
            formControlName="dob"
            id="add-user-dob"
            placeholder="DD-MM-YYYY" type="date">
          <label for="add-user-fullname">Date of Birth</label>
          <ng-container
            *ngIf="userForm.get('dob')?.invalid && (!userForm.get('dob')?.pristine || userForm.get('dob')?.touched)">
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              <ng-container *ngIf="userForm.get('dob')?.hasError('required')">
                Date of birth is required.
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="form-floating form-floating-outline mb-5 fv-plugins-icon-container">
          <input
            [ngClass]="{'is-invalid':userForm.get('email')?.invalid && (!userForm.get('email')?.pristine || userForm.get('email')?.touched)}"
            aria-label="username&#64;gmail.com" class="form-control"
            formControlName="email"
            id="add-user-email" placeholder="username&#64;gmail.com" type="email">
          <label for="add-user-email">Email</label>
          <ng-container
            *ngIf="userForm.get('email')?.invalid && (!userForm.get('email')?.pristine || userForm.get('email')?.touched)">
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              <ng-container *ngIf="userForm.get('email')?.hasError('required')">
                Email is required.
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="form-floating form-floating-outline mb-5">
          <input
            [ngClass]="{'is-invalid':userForm.get('phone')?.invalid && (!userForm.get('phone')?.pristine || userForm.get('phone')?.touched)}"
            aria-label="+91-1234567890" class="form-control phone-mask"
            formControlName="phone"
            id="add-user-contact" placeholder="+91-1234567890" type="text">
          <label for="add-user-contact">Mobile</label>
          <ng-container
            *ngIf="userForm.get('phone')?.invalid && (!userForm.get('phone')?.pristine || userForm.get('phone')?.touched)">
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              <ng-container *ngIf="userForm.get('phone')?.hasError('required')">
                Phone is required.
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="form-floating form-floating-outline mb-5">
          <select
            [ngClass]="{'is-invalid':userForm.get('role')?.invalid && (!userForm.get('role')?.pristine || userForm.get('role')?.touched)}"
            class="form-select" formControlName="role"
            id="user-role">
            <option value="">Select Role</option>
            <option *ngFor="let role of roles;" [value]="role.key">{{role.name | titlecase}}</option>
          </select>
          <label for="user-role">User Role</label>
          <ng-container
            *ngIf="userForm.get('role')?.invalid && (!userForm.get('role')?.pristine || userForm.get('role')?.touched)">
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
              <ng-container *ngIf="userForm.get('role')?.hasError('required')">
                Role is required.
              </ng-container>
            </div>
          </ng-container>
        </div>

        <button [disabled]="userForm.invalid"
                class="btn btn-primary me-sm-3 me-1 data-submit waves-effect waves-light"
                type="submit">
          Submit
        </button>
        <button #closeButton class="btn btn-outline-danger waves-effect" data-bs-dismiss="offcanvas"
                type="reset">Cancel
        </button>
        <ngx-spinner type="ball-pulse" [fullScreen]="false" name="userForm" size="default" bdColor = "rgba(0,0,0,0.5)"></ngx-spinner>
      </form>
    </div>
  </div>
</div>
