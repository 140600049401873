import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MasterService {


  private baseUrl: string = 'https://authorization.mynextsalon.com';

  constructor(private http: HttpClient) {

  }

  getCountries(token: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(this.baseUrl + '/api/countries', {headers});
  }

  getStates(token: string, country_code: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(this.baseUrl + '/api/states?country=' + country_code, {headers});
  }

  getCities(token: string, country_code: string, state_code: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(this.baseUrl + '/api/cities?country=' + country_code + '&state=' + state_code, {headers});
  }

  lookup(token: string, name: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(this.baseUrl + '/api/lookup?name=' + name, {headers});
  }
}
