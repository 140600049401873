<div class="authentication-wrapper authentication-cover">
  <!-- Logo -->
  <a href="index.html" class="auth-cover-brand d-flex align-items-center gap-3">
    <span class="app-brand-logo">
<span style="color:var(--bs-primary);">
  <img src="assets/img/logo/true-invoice-high-resolution-logo.png" alt="" width="30" height="30"
       class="rounded-2">
</span>
</span>
    <span class="app-brand-text demo text-heading fw-semibold">True Invoice</span>
  </a>
  <!-- /Logo -->
  <div class="authentication-inner row m-0">
    <!-- /Left Section -->
    <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
      <div>
        <img src="assets/img/illustrations/auth-cover-login-illustration-light.png"
             class="authentication-image-model d-none d-lg-block" alt="auth-model"
             data-app-light-img="illustrations/auth-cover-login-illustration-light.png"
             data-app-dark-img="illustrations/auth-cover-login-illustration-dark.png">
      </div>
      <img src="assets/img/illustrations/tree.png" alt="tree" class="authentication-image-tree z-n1">
      <img src="assets/img/illustrations/auth-cover-mask-light.png"
           class="scaleX-n1-rtl authentication-image d-none d-lg-block w-75" alt="triangle-bg" height="362"
           data-app-light-img="illustrations/auth-cover-mask-light.png"
           data-app-dark-img="illustrations/auth-cover-mask-dark.png">
    </div>
    <!-- /Left Section -->

    <!-- Login -->
    <div
      class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-5 px-12 py-4">
      <div class="w-px-400 mx-auto pt-5 pt-lg-0">
        <h4 class="mb-1">Welcome to True Invoice! 👋🏻</h4>
        <p class="mb-3">Please sign-in to your account and start the adventure</p>

        <form id="formAuthentication" class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework" [formGroup]="loginForm" (ngSubmit)="login()">
          <div
            class="form-floating form-floating-outline mb-3 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <input type="text" class="form-control" id="email" formControlName="email"
                   [ngClass]="{'is-invalid': loginForm.get('email')?.invalid && (!loginForm.get('email')?.pristine || loginForm.get('email')?.touched)}"
                   placeholder="Enter your email or username or mobile" autofocus="">
            <label for="email">Email or Username or Mobile</label>
            <ng-container
              *ngIf="loginForm.get('email')?.invalid && (!loginForm.get('email')?.pristine || loginForm.get('email')?.touched)">
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                <ng-container *ngIf="loginForm.get('email')?.hasError('required')">
                  Please enter your email or mobile number or username
                </ng-container>
              </div>
            </ng-container>
          </div>


          <div class="col-12 form-password-toggle fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
            <div class="input-group input-group-merge">
              <div class="form-floating form-floating-outline">
                <input
                  [ngClass]="{'is-invalid': loginForm.get('password')?.invalid && (!loginForm.get('password')?.pristine || loginForm.get('password')?.touched) }"
                  [type]="passwordVisibility.password ? 'text' : 'password'"
                  id="password" formControlName="password" class="form-control"
                       placeholder="············">
                <label for="password">Password</label>
              </div>
              <span class="input-group-text cursor-pointer" (click)="togglePasswordVisibility('password')">
                <i class="ri-20px" [ngClass]="{'ri-eye-line': passwordVisibility.password,'ri-eye-off-line': !passwordVisibility.password}"></i>
              </span>
            </div>
            <ng-container
              *ngIf="loginForm.get('password')?.invalid && (!loginForm.get('password')?.pristine || loginForm.get('password')?.touched)">
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                <ng-container *ngIf="loginForm.get('password')?.hasError('required')">
                  Please enter your password
                </ng-container>
              </div>
            </ng-container>
          </div>


          <div class="mb-3 d-flex justify-content-between flex-wrap py-2">
            <div class="form-check mb-0">
              <input class="form-check-input" type="checkbox" id="remember-me" formControlName="rememberme">
              <label class="form-check-label me-2" for="remember-me">
                Remember Me
              </label>
            </div>
            <a routerLink="/forgot-password" class="float-end mb-1">
              <span>Forgot Password?</span>
            </a>
          </div>
          <button class="btn btn-primary d-grid w-100 waves-effect waves-light" [disabled]="loading || loginForm.invalid" type="submit">
            Login
          </button>
          </form>

        <p class="text-center">
          <span>New on our platform? </span>
          <a routerLink="/register">
            <span>Create an account</span>
          </a>
        </p>
      </div>
    </div>
    <!-- /Login -->
  </div>
</div>
