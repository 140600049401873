import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-register-cover',
  standalone: true,
  providers: [AuthService],
  imports: [
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './register-cover.component.html',
  styleUrl: './register-cover.component.scss'
})
export class RegisterCoverComponent implements OnInit {

  public registerForm!: FormGroup;
  public loading: boolean = false;
  public passwordVisibility = {
    password: false,
    otp: false,
  };

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {

  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      store_name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      name: new FormControl('', [Validators.required, Validators.minLength(4)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d{10}$/),
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.min(0)
      ]),
      password: new FormControl('', [Validators.required]),
      otp: new FormControl('', [Validators.required]),
      terms: new FormControl(false, [Validators.requiredTrue])
    })
  }

  registerUser() {
    this.loading = true;
    const payload = this.registerForm.getRawValue();
    this.registerForm.disable();
    this.authService.registerUser(payload).subscribe({
      next: (response) => {
        console.log('data', response)
        this.registerForm.enable();
        this.loading = false;
        if (response?.status === 200) {
          this.router.navigate(['/login']);
        }
      },
      error: (error) => {
        console.log('error', error);
        this.registerForm.enable();
        this.loading = false;
      }, complete: () => {
        this.loading = false;
        this.registerForm.enable();
      }
    })
  }

  togglePasswordVisibility(field: 'password' | 'otp'): void {
    this.passwordVisibility[field] = !this.passwordVisibility[field];
  }

}
